const districtSpecialTaskForceRoutes = (prop) => [
  {
    path: 'district-special-task-force-activity',
    name: prop + '.market_inspection_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/district-special-task-force-activity/List.vue')
  },
  {
    path: 'report',
    name: prop + '.report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/district-special-task-force-activity/Report.vue')
  },
  {
    path: 'recommendation-report',
    name: prop + '.recommendation_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/district-special-task-force-activity/RecommendationReport.vue')
  },
  {
    path: 'summary-report',
    name: prop + '.summary_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/district-special-task-force-activity/SummaryReport.vue')
  }
]

const routes = [
  {
    path: '/bazar-monitoring-service/special-task-force',
    name: 'bazar_monitoring_service.special_task_force',
    component: () => import('@/layouts/BazarMonitoringServiceLayout.vue'),
    meta: { auth: true },
    children: districtSpecialTaskForceRoutes('bazar_monitoring_service.special_task_force')
  }
]

export default routes
