// ITF Routes
const itfReportRoutes = (prop) => [
  {
    path: 'exhibit-info-report',
    name: prop + '.exhibit_info_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/itf/exhibit-info-report/List.vue')
  },
  {
    path: 'fair-calendar-report',
    name: prop + '.fair_calendar_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/itf/fair-calendar-report/List.vue')
  },
  {
    path: 'applicatipon-report',
    name: prop + '.applicatipon_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/itf/application-report/List.vue')
  },
  {
    path: 'ledger-report',
    name: prop + '.ledger_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/itf/ledger-report/List.vue')
  }
]

// DIFT Routes
const ditfReportRoutes = (prop) => [
  {
    path: 'application-report',
    name: prop + '.application_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/application-report/List.vue')
  },
  {
    path: 'application-history-report',
    name: prop + '.application_history_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/application-transaction-report/List.vue')
  },
  {
    path: 'stall-booked-report',
    name: prop + '.stall_booked_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/stall-booked-report/List.vue')
  },
  {
    path: 'stall-wise-bidding-report',
    name: prop + '.stall_wise_bidding_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/stall-wise-bidding-report/List.vue')
  },
  {
    path: 'bidding-report',
    name: prop + '.bidding_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/bidding-report/List.vue')
  },
  {
    path: 'accounts-balance-sheet',
    name: prop + '.accounts_balance_sheet',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/accounts-balance-sheet/List.vue')
  },
  {
    path: 'balance-sheet',
    name: prop + '.balance_sheet',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/balance-sheet/List.vue')
  },
  {
    path: 'security-forfeiture-report',
    name: prop + '.security_forfeiture_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/security-forfeiture-report/List.vue')
  },
  {
    path: 'scrutiny-report',
    name: prop + '.scrutiny_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/scrutiny-report/List.vue')
  },
  {
    path: 'company-selection-report',
    name: prop + '.company_selection_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/company-selection-report/List.vue')
  },
  {
    path: 'refund-report',
    name: prop + '.refund_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/refund-report/List.vue')
  },
  {
    path: 'ledger-report',
    name: prop + '.ledger_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/ledger-report/List.vue')
  },
  {
    path: 'bank-report',
    name: prop + '.bank_report',
    meta: { auth: true, name: 'Editable' },
    component: () => import('../pages/ditf/bank-report/List.vue')
  }
]

const routes = [
  {
    path: '/trade-fair-service/itf-report',
    name: 'trade_fair_service.itf_report',
    component: () => import('@/layouts/TradeFairServiceLayout.vue'),
    meta: { auth: true },
    children: itfReportRoutes('trade_fair_service.itf_report')
  },
  {
    path: '/trade-fair-service/ditf-report',
    name: 'trade_fair_service.ditf_report',
    component: () => import('@/layouts/TradeFairServiceLayout.vue'),
    meta: { auth: true },
    children: ditfReportRoutes('trade_fair_service.ditf_report')
  }
]

export default routes
